<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import appConfig from "@/app.config";
import {authService} from "@/services/auth.service";

/**
 * Login component
 */
export default {
  page: {
    title: "Connexion",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "api") {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          let result = await authService.login({email: this.email, password: this.password,})
          if (result.status === 200) {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            // this.$store.commit('setUserInfo', result.data.userInfo);
            await this.$router.push(
                this.$route.query.redirectFrom || {name: "home"}
            );
          } else if (result.status >= 400) {
            this.tryingToLogIn = false;
            this.authError = result.status === 404 ? "Identifiant ou mot de passe incorrect" : "Une erreur s'est produite";
            this.isAuthError = true;
          }
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          const { email, password } = this;
          if (email && password) {
            this.login({
              email,
              password,
            });
          }
        }
      }
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="card overflow-hidden">
            <div class="bg-gardepharma">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20">Bienvenue !</h5>
                <p class="text-white-50">Connectez-vous au backoffice de Garde Pharma</p>
                <a href="/" class="logo logo-admin">
                  <img
                    src="@/assets/images/logo-sm.png"
                    height="60"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="p-3">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >
                <div
                  v-if="notification.message"
                  :class="'alert ' + notification.type"
                >
                  {{ notification.message }}
                </div>

                <b-form @submit.prevent="tryToLogIn" class="form-horizontal mt-4">
                  <b-form-group id="input-group-1" label="Email" label-for="input-1" class="mb-3" label-class="form-label">
                    <b-form-input id="input-1" :class="{ 'is-invalid': submitted && $v.email.$error }" v-model="email"
                      type="email" placeholder="Saisir email"></b-form-input>
                    <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                      <span v-if="!$v.email.required">Email est requis.</span>
                      <span v-if="!$v.email.email">Veuillez saisir un e-mail valide.</span>
                    </div>
                  </b-form-group>

                  <b-form-group id="input-group-2" label="Mot de passe" label-for="input-2" class="mb-3" label-class="form-label">
                    <b-form-input id="input-2" v-model="password" type="password" placeholder="Saisir Mot de passe"
                      :class="{ 'is-invalid': submitted && $v.password.$error }"></b-form-input>
                    <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                      Mot de passe est requis
                    </div>
                  </b-form-group>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customControlInline"/>
                        <label class="form-check-label" for="customControlInline">se souvenir de moi</label>
                      </div>
                    </div>
                    <div class="col-sm-6 text-end">
                      <b-button type="submit" variant="gardepharma" class="w-md">Se connecter</b-button>
                    </div>
                  </div>

                  <div class="mt-2 mb-0 row">
                    <div class="col-12 mt-4">
                      <router-link to="/forgot-password">
                        <i class="mdi mdi-lock"></i> Mot de passe oublié ?
                      </router-link>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <!--<p>
              Don't have an account ?
              <router-link to="/register" class="fw-medium text-primary">Signup now</router-link>
            </p>-->
            <p class="mb-0">© {{ new Date().getFullYear() }} Garde Pharma. par TOSFABRE Dev
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style scoped>
.bg-gardepharma {
  background-color: #007200;
}
.btn-gardepharma {
  color: #fff;
  background-color: #007200;
  border-color: #007200;
}
.btn-gardepharma:hover {
  background-color: #003f00;
  border-color: #003f00;
}
</style>
